@import '../../../../scss/theme-bootstrap';

.header-offers-banner-formatter {
  min-height: 42px;
  display: flex;
  align-items: center;
  width: 100%;
  @media #{$medium-up} {
    min-height: 56px;
  }
  &__carousel,
  &__carousel.slick-slider {
    margin-bottom: 0;
    width: 100%;
  }
}
